import React, { useState } from "react";
import { Button } from "@travelquest/web-components";
import { ContextType, useAppState } from "../../store";
import {
  Steps,
  Page,
  Card,
  BookingInformation,
  Loader,
  GetPreviousValidStep,
  GetNextValidStep,
} from "../../components";

import { calculateTravelInsurance } from "../../helpers";
import { Layout, Header, Buttons, HeaderInfo } from "./BookExtras.styled";
import { Traveller } from "./Traveller";
import { UseGetBooking } from "../../hooks/UseGetBooking";
import { UseUpdateTraveller } from "../../hooks/UseUpdateTraveller";
import { useQuery } from "@apollo/client";
import { GET_COMBO_DEALS } from "../../queries/comboDeal";

const filterExcursions = (excursions: ExcursionType[]) => {
  const result: Record<string, any> = {};
  excursions?.forEach((ex) => {
    if (result[ex.type]) {
      result[ex.type].push(ex);
    } else {
      result[ex.type] = [ex];
    }
  });
  return result;
};

export const BookExtras = () => {
  const {
    appState: { bookingId, brand },
  } = useAppState() as ContextType;
  const { booking, loading } = UseGetBooking(bookingId);
  const { updateTraveller } = UseUpdateTraveller();
  const [openTraveller, setOpenTraveller] = useState<number | null>(0);
  const update = (id: string, values: Record<string, any>) =>
    updateTraveller({
      id,
      values,
      bookingId,
      initTravellers: booking?.travellers,
    });

  const { data, loading: comboDealsLoading } = useQuery(GET_COMBO_DEALS, {
    variables: {
      brand: brand?.id,
      brandDestination: booking?.brandAccommodation?.brandDestination?.id,
    },
  });

  const comboDeals = data?.comboDeals;
  if (loading || !booking || comboDealsLoading) {
    return <Loader />;
  }

  const nextStep = GetNextValidStep(booking, 6);
  const excursions = filterExcursions(
    booking?.brandAccommodation?.brandDestination?.excursions
  );

  const travelInsurancePrice = calculateTravelInsurance(
    booking.period,
    booking.transport
  );
  const prices =
    booking?.period?.prices?.[booking?.mealType]?.[booking?.transport];
  const roomsById: Record<string, RoomType> = {};
  booking.rooms.forEach((r) => {
    roomsById[r.id] = r;
  });

  const previousStep = GetPreviousValidStep(booking, 6);

  return (
    <Page>
      <Steps current={5} />
      <Layout>
        <BookingInformation booking={booking} loading={loading} />
        <div>
          <Card>
            <Header>Excursies en verzekeringen</Header>
            <HeaderInfo>
              Kies per persoon welke eventuele extra's je wenst toe te voegen
              aan je reservatie.
            </HeaderInfo>
            {booking.travellers.map((t, i) => (
              <Traveller
                comboDeals={comboDeals}
                periodStart={booking?.period?.start}
                prices={prices}
                room={roomsById[t.room]?.size}
                index={i}
                travelInsurancePrice={travelInsurancePrice}
                isOpen={openTraveller === i}
                setOpen={setOpenTraveller}
                travellerData={t}
                key={t.id}
                excursions={excursions.excursion}
                events={excursions.event}
                update={(values) => update(t.id, values)}
                partyDeals={excursions.party}
                insurances={
                  booking.brandAccommodation?.brandDestination?.insurances
                }
              />
            ))}
          </Card>
          <Buttons>
            <a
              href={`/step${previousStep}?id=${bookingId}&brand=${brand.code}`}
            >
              <Button color="#655C7E">Vorige stap</Button>
            </a>
            <a href={`/step${nextStep}?id=${bookingId}&brand=${brand.code}`}>
              <Button color={brand.color}>Volgende stap</Button>{" "}
            </a>
          </Buttons>
        </div>
      </Layout>
    </Page>
  );
};
