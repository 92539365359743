import { getPriceForAddon } from "../../helpers";

export const calculateAddons = (
  travellers: TravellerType[],
  addons: AddonType[],
  start: number
) => {
  const result = {
    total: 0,
    price: 0,
  };
  travellers?.forEach((t) => {
    t?.addons?.forEach((addonObj: any) => {
      const addon = addons.find((x) => x.id === addonObj?.addonId);
      if (addon) {
        result.total++;
        result.price =
          result.price +
          getPriceForAddon(addon.pricing, addon.defaultPrice, start);
      }
    });
  });
  return result;
};
