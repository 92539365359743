import React, { useState } from "react";
import { Button } from "@travelquest/web-components";
import { ContextType, useAppState } from "../../store";
import {
  Steps,
  Page,
  Card,
  BookingInformation,
  Loader,
} from "../../components";

import {
  Layout,
  Header,
  Buttons,
  DropdownWrapper,
  ButtonWrapper,
  TinyButton,
} from "./BookExtras.styled";
import { Traveller } from "./Traveller";
import { UseGetBooking } from "../../hooks/UseGetBooking";
import { UseUpdateTraveller } from "../../hooks/UseUpdateTraveller";

export const addonCategoriesMapping: Record<string, string> = {
  basicNeeds: "Basis benodigdheden",
  skiMaterial: "Ski materiaal",
  snowboardMaterial: "Snowboard materiaal",
  skiPass: "Ski pas",
  skiLessons: "Ski lessen",
  insurance: "Verzekering",
};

export const BookAddons = () => {
  const {
    appState: { bookingId, brand },
  } = useAppState() as ContextType;
  const { booking, loading } = UseGetBooking(bookingId);
  const { updateTraveller } = UseUpdateTraveller();
  const [openTraveller, setOpenTraveller] = useState<number | null>(0);
  const [selectedCategory, setSelectedCategory] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [selectedTag, setSelectedTag] = useState<{
    label: string;
    value: string;
  } | null>(null);

  if (loading || !booking) {
    return <Loader />;
  }

  const roomsById: Record<string, RoomType> = {};
  booking.rooms.forEach((r) => {
    roomsById[r.id] = r;
  });

  const addons = booking?.brandAccommodation?.brandDestination?.addons;

  const categoryOptions: any = addons?.reduce((acc: any, addon) => {
    const addonCategories =
      addon?.categories?.map((category) => ({
        value: category,
        label: addonCategoriesMapping?.[category],
      })) || [];

    addonCategories?.forEach((category) => {
      if (!acc.some((option: any) => option.value === category.value)) {
        acc.push(category);
      }
    });
    return acc;
  }, []);

  const addonsFilteredOnTags = addons?.filter((addon) => addon?.tags !== null);

  const tagsOptions: any = addonsFilteredOnTags?.reduce((acc: any, addon) => {
    if (
      addon?.categories?.includes(selectedCategory || categoryOptions[0]?.value)
    ) {
      const addonTags =
        addon?.tags?.map((tag) => ({ value: tag, label: tag })) || [];
      addonTags?.forEach((tag) => {
        if (!acc.some((option: any) => option.value === tag.value)) {
          acc.push(tag);
        }
      });
    }
    return acc;
  }, []);
  const finalFilterAddons = addons?.filter((addon) => {
    const tagToUse = selectedTag || tagsOptions?.[0]?.value;

    const categoryToUse = selectedCategory || categoryOptions?.[0]?.value;

    if (!tagToUse && !categoryToUse) {
      return true;
    }

    return (
      addon?.tags?.includes(tagToUse) &&
      addon?.categories?.includes(categoryToUse)
    );
  });

  const currentSelectedCategory =
    selectedCategory || categoryOptions?.[0]?.value;

  const currentSelectedTag = selectedTag || tagsOptions?.[0]?.value;

  return (
    <Page>
      <Steps current={6} />
      <Layout>
        <BookingInformation booking={booking} loading={loading} />
        <div>
          <Card>
            <Header>Extra's</Header>
            <p>
              Kies per persoon welke eventuele extra's je wenst toe te voegen
              aan je reservatie.
            </p>
            <DropdownWrapper>
              <ButtonWrapper>
                {categoryOptions?.map((category: any, i: number) =>
                  category?.label ? (
                    <Button
                      color={
                        currentSelectedCategory === category?.value
                          ? brand.color
                          : "grey"
                      }
                      key={category?.label}
                      onClick={() => {
                        setSelectedCategory(category?.value);
                        setSelectedTag(null);
                      }}
                    >
                      {category?.label}
                    </Button>
                  ) : null
                )}
              </ButtonWrapper>
              <ButtonWrapper>
                {tagsOptions?.map((tag: any, i: number) =>
                  tag?.label ? (
                    <TinyButton
                      key={tag?.label}
                      color={
                        currentSelectedTag === tag?.value ? brand.color : "grey"
                      }
                      onClick={() => {
                        setSelectedTag(tag?.value);
                      }}
                    >
                      {tag?.label}
                    </TinyButton>
                  ) : null
                )}
              </ButtonWrapper>
            </DropdownWrapper>
            {booking?.travellers.map((t, i) => (
              <Traveller
                index={i}
                isOpen={openTraveller === i}
                setOpen={setOpenTraveller}
                travellerData={t}
                key={t.id}
                finalFilterAddons={finalFilterAddons}
                update={(values) => updateTraveller({ id: t.id, values })}
                selectedAddons={t.addons || []}
                period={booking?.period}
              />
            ))}
          </Card>
          <Buttons>
            <a href={`/step6?id=${bookingId}&brand=${brand.code}`}>
              <Button color="#655C7E">Vorige stap</Button>
            </a>
            <a href={`/step8?id=${bookingId}&brand=${brand.code}`}>
              <Button color={brand.color}>Volgende stap</Button>
            </a>
          </Buttons>
        </div>
      </Layout>
    </Page>
  );
};
