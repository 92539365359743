import React from "react";
import { MinIcon, PlusMathIcon } from "@travelquest/web-components";

import { Checkbox } from "../../../components";
import {
  TravelerContent,
  TravellerObject,
  TravellerHeader,
  Title,
} from "./Traveller.styled";
import { useTranslation } from "react-i18next";
import { getPriceForExcursion } from "../../../helpers/getPriceForExcursion";
import { gql, useMutation } from "@apollo/client";
import {
  ADD_TRAVELLER_EXCURSION,
  REMOVE_TRAVELLER_EXCURSION,
} from "../../../queries/travellerExcursions";

type Props = {
  isOpen: boolean;
  setOpen: (index: number | null) => void;
  update: (values: Record<string, any>) => void;
  index: number;
  events: ExcursionType[];
  comboDeals: any[];
  excursions: ExcursionType[];
  partyDeals: ExcursionType[];
  room: number;
  prices: any[];
  travelInsurancePrice: number;
  travellerData: TravellerType;
  periodStart: number;
  insurances: Insurance[];
};


function filterInsurances(insurances: Insurance[]): Insurance[] {
  const insuranceMap: { [key: string]: Insurance[] } = {};

  insurances.forEach((insurance) => {
    if (!insuranceMap[insurance.type]) {
      insuranceMap[insurance.type] = [];
    }
    insuranceMap[insurance.type].push(insurance);
  });

  const result: Insurance[] = [];

  for (const type in insuranceMap) {
    const insurancesOfType = insuranceMap[type];
    if (insurancesOfType.length > 1) {
      const nonAllDestinationsInsurance = insurancesOfType.find(
        (insurance) => !insurance.allDestinations
      );
      if (nonAllDestinationsInsurance) {
        result.push(nonAllDestinationsInsurance);
      } else {
        result.push(insurancesOfType[0]);
      }
    } else {
      result.push(insurancesOfType[0]);
    }
  }

  return result;
}


export const Traveller: React.FC<Props> = ({
  isOpen,
  setOpen,
  index,
  travellerData,
  excursions = [],
  events = [],
  partyDeals = [],
  update,
  periodStart,
  comboDeals = [],
  insurances,
}) => {
  const {
    hasTravelInsurance,
    hasAnnulationInsurance,
    firstName,
    lastName,
    excursions: travellerExcursions,
    hasBothInsurances,
  } = travellerData;
  const { t } = useTranslation("addons");

  const [addTravellerExcursion, { loading }] = useMutation(
    ADD_TRAVELLER_EXCURSION
  );
  const [removeTravellerExcursion, { loading: removeLoading }] = useMutation(
    REMOVE_TRAVELLER_EXCURSION
  );

  const checkInsurance = (insurance: Insurance) => {
    switch (insurance?.type) {
      case "annulation":
        update({
          hasAnnulationInsurance: !hasAnnulationInsurance,
        });
        break;
      case "travel":
        update({
          hasTravelInsurance: !hasTravelInsurance,
        });
        break;
      case "annulationTravel":
        update({
          hasBothInsurances: !hasBothInsurances,
        });
        break;
      default:
        console.error("Unknown insurance type");
    }
  };

  const addOrRemove = (exc: ExcursionType) => {
    // Check if ID is present
    const e: TravellerExcursion | undefined = travellerExcursions.find(
      (e) => e.excursionId === exc.id
    );
    if (e?.id) {
      removeTravellerExcursion({
        variables: {
          id: e.id,
        },
        update: async (
          cache: any,
          { data: { removeTravellerExcursion } }: any
        ) => {
          if (removeTravellerExcursion.success) {
            const normalizedId = cache.identify({
              id: e.id,
              __typename: "TravellerExcursion",
            });

            cache.evict({ id: normalizedId });
            cache.gc();
          } else {
            console.error("failed to remove Traveller exc");
          }
        },
      });
    } else {
      const { id: excID, ...rest } = exc;
      addTravellerExcursion({
        variables: {
          input: {
            travellerId: travellerData.id,
            paymentOption: "mollie",
            excursionId: exc.id,
            price: getPriceForExcursion(
              rest.pricing,
              rest.defaultPrice,
              periodStart
            ),
            name: rest.name.nlbe,
            type: "booking",
          },
        },
        update: async (
          cache: any,
          { data: { addTravellerExcursion } }: any
        ) => {
          if (addTravellerExcursion.success) {
            const cacheData: Record<string, any> = cache.readFragment({
              id: `Traveller:${travellerData.id}`,
              fragment: gql`
                fragment MyTravellerExcursions on Traveller {
                  id
                  excursions {
                    id
                    excursionId
                    price
                    name
                  }
                }
              `,
            });
            cache.writeFragment({
              id: `Traveller:${travellerData.id}`,
              fragment: gql`
                fragment MyTravellerExcursions on Traveller {
                  id
                  excursions {
                    id
                    excursionId
                    price
                    name
                  }
                }
              `,
              data: {
                __typename: "Traveller",
                id: travellerData.id,
                excursions: [
                  ...(cacheData?.excursions || []),
                  {
                    __typename: "TravellerExcursion",
                    id: addTravellerExcursion.id,
                    excursionId: exc.id,
                    price: getPriceForExcursion(
                      rest.pricing,
                      rest.defaultPrice,
                      periodStart
                    ),
                    name: rest.name.nlbe,
                  },
                ],
                priceChange: true,
              },
            });
          } else {
            console.error("failed to add Traveller exc");
          }
        },
      });
    }
  };
  return (
    <TravellerObject>
      <TravellerHeader onClick={() => setOpen(isOpen ? null : index)}>
        {firstName} {lastName}
        {isOpen ? <MinIcon /> : <PlusMathIcon />}
      </TravellerHeader>
      {isOpen && (
        <TravelerContent>
          <div>
            <Title>Verzekeringen</Title>
            <p>
              Verzekeringen kan je enkel toevoegen tijdens het reserveren van je
              reis. <br />
              Je kan dit achteraf niet meer toevoegen en/of verwijderen.
            </p>
            {filterInsurances(insurances)?.map((insurance) => (
              <Checkbox
                id={insurance.id}
                value={
                  insurance?.type === "travel"
                    ? hasTravelInsurance
                    : insurance?.type === "annulation"
                    ? hasAnnulationInsurance
                    : insurance?.type === "annulationTravel"
                    ? hasBothInsurances
                    : false
                }
                callback={() => checkInsurance(insurance)}
                key={insurance.id}
                price={insurance.price}
              >
                {insurance?.type === "travel" && t("addons:travelinsurance")}
                {insurance?.type === "annulation" &&
                  t("addons:annulationInsurance")}
                {insurance?.type === "annulationTravel" &&
                  t("addons:bothInsurance")}
              </Checkbox>
            ))}
          </div>
          <div>
            {comboDeals?.length ? <Title>Summer Bash Pakketten</Title> : null}
            {comboDeals?.map((comboDeal) => (
              <Checkbox
                id={comboDeal.id}
                value={
                  !!travellerExcursions?.find(
                    (exc) => exc.excursionId === comboDeal.id
                  )
                }
                callback={() => addOrRemove(comboDeal)}
                key={comboDeal.id}
                price={comboDeal.price}
                showTooltip={!comboDeal.bookable}
                disabled={!comboDeal.bookable || loading || removeLoading}
              >
                {comboDeal.name.nlbe}
                <ul>
                  {comboDeal.excursions?.map((x: any) => (
                    <li>{x.name?.nlbe}</li>
                  ))}
                </ul>
              </Checkbox>
            ))}
            {excursions.length ? <Title>Excursies</Title> : null}
            {excursions.map((excursion) => (
              <Checkbox
                id={excursion.id}
                value={
                  !!travellerExcursions?.find(
                    (exc) => exc.excursionId === excursion.id
                  )
                }
                callback={() => addOrRemove(excursion)}
                key={excursion.id}
                price={getPriceForExcursion(
                  excursion.pricing,
                  excursion.defaultPrice,
                  periodStart
                )}
                showTooltip={!excursion.bookable}
                disabled={!excursion.bookable || loading || removeLoading}
              >
                {excursion.name.nlbe}
              </Checkbox>
            ))}
            {events.length ? <Title>Events</Title> : null}
            {events.map((event) => (
              <Checkbox
                id={event.id}
                value={
                  !!travellerExcursions.find((e) => e.excursionId === event.id)
                }
                key={event.id}
                price={getPriceForExcursion(
                  event.pricing,
                  event.defaultPrice,
                  periodStart
                )}
                disabled={!event.bookable || loading || removeLoading}
                showTooltip={!event.bookable}
                callback={() => addOrRemove(event)}
              >
                {event.name.nlbe}
              </Checkbox>
            ))}
            {partyDeals.length ? <Title>Party deals</Title> : null}
            {partyDeals.map((deal) => (
              <Checkbox
                id={deal.id}
                value={
                  !!travellerExcursions?.find(
                    (exc) => exc.excursionId === deal.id
                  )
                }
                callback={() => addOrRemove(deal)}
                key={deal.id}
                price={getPriceForExcursion(
                  deal.pricing,
                  deal.defaultPrice,
                  periodStart
                )}
                showTooltip={!deal.bookable}
                disabled={!deal.bookable || loading || removeLoading}
              >
                {deal.name.nlbe}
              </Checkbox>
            ))}
          </div>
        </TravelerContent>
      )}
    </TravellerObject>
  );
};
