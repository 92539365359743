export const calculateTotalPrice = (
  rooms: RoomType[],
  prices: PriceType[],
  travelInsurances: number,
  annulationInsurance: number,
  bothInsurance: number,
  volunteerFee: number
) => {
  let total = 0;
  let totalTravellers = 0;
  rooms.forEach((x) => {
    const price = prices[x.size];
    total = total + Math.ceil(Number(price.discounted)) * (x.size + 1);
    totalTravellers = totalTravellers + x.size + 1;
  });
  total = total + travelInsurances + annulationInsurance + bothInsurance;

  // add volunteer fee
  total = total + totalTravellers * volunteerFee;
  return Math.ceil(total);
};
