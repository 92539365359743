import styled from "styled-components";
import { adjustHue } from "polished";
import { Card } from "../Card";
import { Stars } from "../Stars";
import { media } from "../../helpers/mediaQueries";

export const HotelInfo = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`;

export const TimezoneWarning = styled.div<{ color: string }>`
  font-size: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
    margin-right: 5px;
    height: 20px;
    width: 20px;
    color: ${({ color }) => color};
  }
`;

export const HotelImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 20px;
  margin-right: 16px;
`;

export const HotelName = styled.div`
  color: #231747;
  font-family: Rubik;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  ${media.tablet`
  font-size: 16px;
`};
`;

export const StyledStars = styled(Stars)`
  font-size: 16px;
  margin-left: 16px;
  ${media.tablet`
  font-size: 8px;
  margin-left: 5px;
`};
`;

export const HotelLocation = styled.div<{ color: string }>`
  color: #655c7e;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 20px;
  svg {
    color: ${({ color }) => color};
  }
  ${media.tablet`
  font-size: 10px;
`};
`;

export const StyledCard = styled(Card)`
  padding: 0;
  justify-content: flex-start;
  align-items: flex-start;
  ${media.tablet`
  display: none;
`};
`;

export const StyledCardMobile = styled(Card)`
  padding: 0;
  justify-content: flex-start;
  align-items: flex-start;
  display: none;
  ${media.tablet`
    display: flex;
    flex-direction: row;
  `};
`;

export const CardLine = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.2;
  background-color: rgb(138 144 144 / 84%);
  ${media.tablet`
  margin: 10px 0;
`};
`;

export const Field = styled.div`
  padding: 18px 24px;
  ${media.tablet`
  padding: 5px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;

  & > .tooltip {
    width: 200px;
  }
`};
`;

export const StyledIcon = styled.div`
  svg {
    color: ${({ theme }) => theme.greyLight};
    margin-top: 20px;
    padding: 0 24px;
    ${media.tablet`
    margin-top: 0;
    `}
  }
`;

export const MultipleField = styled.div<{ $isMobile?: boolean }>`
  box-sizing: border-box;
  padding: 18px 24px;
  width: 100%;
  display: ${({ $isMobile }) => ($isMobile ? "none" : "flex")};
  justify-content: flex-start;
  align-items: center;
  ${({ $isMobile }) =>
    $isMobile &&
    media.tablet`
  padding: 5px 8px;
  ${StyledIcon} {
    margin-top: 0;
  }
  display: flex;
  ${Value} {
    font-size: 10px;
  }
`};
`;

export const Title = styled.div`
  color: #655c7e;
  text-transform: capitalize;
  span {
    text-transform: capitalize;
    display: inline-block;
    margin-left: 5px;
  }
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 16px;
  margin-bottom: 4px;
  display: flex;
`;

export const Value = styled.div`
  color: #231747;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
`;

export const TotalBlock = styled.div<{ color: string }>`
  padding: 18px 24px;
  width: 100%;
  background: ${({ color }) =>
    `linear-gradient(43.54deg, ${adjustHue(-20, color)} 0%, ${color} 100%)`};
`;

export const TotalText = styled.div`
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 21px;
`;

export const TotalPrice = styled.div`
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
`;
