import React from "react";
import { MinIcon, PlusMathIcon } from "@travelquest/web-components";
import {
  TravelerContent,
  TravellerObject,
  TravellerHeader,
} from "./Traveller.styled";
import { Checkbox } from "../../../components";
import { getPriceForAddon } from "../../../helpers";

type Props = {
  isOpen: boolean;
  setOpen: (index: number | null) => void;
  update: (values: Record<string, any>) => void;
  index: number;
  finalFilterAddons: AddonType[];
  travellerData: TravellerType;
  selectedAddons: TravellerAddon[];
  period: PeriodType;
};

const updateArray = (
  toBeAdded: boolean,
  value: TravellerAddon,
  array: TravellerAddon[]
) => {
  const _array = [...array];
  if (toBeAdded) {
    _array.push(value);
  } else {
    let index = _array.findIndex((x) => x.addonId === value.addonId);
    _array.splice(index, 1);
  }

  return _array;
};

export const Traveller: React.FC<Props> = ({
  isOpen,
  setOpen,
  index,
  finalFilterAddons,
  travellerData,
  update,
  selectedAddons,
  period,
}) => {
  const { firstName, lastName } = travellerData;
  const selectedIds = selectedAddons?.map(
    (addon: TravellerAddon) => addon.addonId
  );
  return (
    <TravellerObject>
      <TravellerHeader onClick={() => setOpen(isOpen ? null : index)}>
        {firstName} {lastName}
        {isOpen ? <MinIcon /> : <PlusMathIcon />}
      </TravellerHeader>
      {isOpen && (
        <TravelerContent>
          {finalFilterAddons?.map((addon) => (
            <Checkbox
              id={addon.id}
              value={selectedIds?.includes(addon.id)}
              callback={(bool) => {
                update({
                  addons: updateArray(
                    bool,
                    {
                      addonId: addon.id,
                      name: addon.name.nlbe,
                      price: getPriceForAddon(
                        addon.pricing,
                        addon.defaultPrice,
                        period?.start
                      ),
                      bookedInOnlineAccount: true,
                    },
                    selectedAddons
                  ),
                });
              }}
              key={addon.id}
              price={getPriceForAddon(
                addon.pricing,
                addon.defaultPrice,
                period?.start
              )}
            >
              {addon.name.nlbe}
            </Checkbox>
          ))}
        </TravelerContent>
      )}
    </TravellerObject>
  );
};
