import { gql } from "@apollo/client";

export const Traveller = `
id
firstName
lastName
gender
dateOfBirth
registryNr
emergencyPhone
parentFirstName
parentLastName
parentRegistryNr
parentEmail
parentPhone
extraEmail
email
phone
street
houseAddition
room
city
zip
country
hasTravelInsurance
hasAnnulationInsurance
hasBothInsurances
pricing {
    insurances {
      name 
      price
    }
}
excursions {
  id
  price
  excursionId
  name
}
addons {
  addonId
  name
  price
}`;

export const INIT_TRAVELLERS = gql`
  mutation initTravellers($bookingId: String!, $count: Int!) {
    initTravellers(bookingId: $bookingId, count: $count) {
      message
      data {
        ${Traveller}
      }
      success
    }
  }
`;

export const UPDATE_TRAVELLER = gql`
  mutation updateTraveller($input: TravellerInput!) {
    updateTraveller(input: $input) {
      message
      success
    }
  }
`;

export const REMOVE_TRAVELLER = gql`
  mutation removeTraveller($id: String!) {
    removeTraveller(id: $id) {
      message
      success
    }
  }
`;
