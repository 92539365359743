import { gql, ApolloError, useMutation } from "@apollo/client";
import { UPDATE_TRAVELLER } from "../queries/traveller";

type Props = {
  id: string;
  values: Record<string, any>;
};

type Result = {
  loading: boolean;
  error?: ApolloError;
  updateTraveller: Function;
};

const getFragment = (values: Record<string, any>) => {
  return gql`
    fragment MyTraveller on Traveller {
      id
      ${Object.keys(values)}
    }
  `;
};

export const UseUpdateTraveller = (): Result => {
  const [updateTraveller, { loading, error }] = useMutation(UPDATE_TRAVELLER);

  const update = ({ id, values }: Props) => {
    updateTraveller({
      variables: {
        input: {
          id: id,
          ...values,
        },
      },
      update(cache, { data: { updateTraveller } }) {
        if (updateTraveller.success) {
          const data = cache.readFragment({
            id: `Traveller:${id}`,
            fragment: getFragment(values),
          });

          cache.writeFragment({
            id: `Traveller:${id}`,
            fragment: getFragment(values),
            data: {
              __typename: "Traveller",
              ...(data as TravellerType),
              ...values,
            },
          });
        } else {
          console.error("failed to update traveller");
        }
      },
    });
  };

  return { loading, error, updateTraveller: update };
};
